import { HttpClient } from '@angular/common/http';
import { ElementRef, Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { IApplication } from '@model/interfaces/application';
import { ApplicationStatuses } from '@model/enums/application-statuses.enum';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { IApplicationValidationDTO } from '@model/interfaces/custom/application-validation-dto';
import { FormSections } from '@model/enums/form-sections.enum';
import { FormGroup } from '@angular/forms';
import { IPersonalInformationMetaData } from '@model/interfaces/custom/personal-information-meta-data';
import { IDonor } from '@model/interfaces/donor';
import { IApplicationImageDTO } from '@model/interfaces/custom/application-images-dto';
import { ILoggedIn } from '@mt-ng2/auth-module';
import * as JSZip from 'jszip';

export const emptyApplication: IApplication = {
    ApplicationStatusId: ApplicationStatuses.PostRegistration,
    Archived: false,
    DateCreated: new Date(),
    DenialReasonId: null,
    DonorId: 0,
    Id: 0,
    UserId: 0,
};

export interface ICurrentSectionAndStatus {
    sectionId: FormSections;
    status: string;
    nextTab: string;
    nextTabLabel: string;
    formGroup: FormGroup;
    routerLink: string[];
}

@Injectable({
    providedIn: 'root',
})
export class ApplicationService extends BaseService<IApplication> {
    currentSectionAndStatus: BehaviorSubject<ICurrentSectionAndStatus> = new BehaviorSubject<ICurrentSectionAndStatus>({
        formGroup: null,
        nextTab: 'tab-basicsComplete',
        nextTabLabel: 'Basic Info',
        routerLink: null,
        sectionId: 0, // FormSections.Basicinfo,
        status: '',
    });

    // used to trigger mark all fields as touched on form sections
    applicationSaved$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    constructor(public http: HttpClient) {
        super('/donor-portal/application', http);
    }

    getEmptyApplication(): IApplication {
        return { ...emptyApplication };
    }

    getPendingApplication(): Observable<IApplication> {
        return this.http.get<IApplication>(`/api/v1/donor-portal/application/pending-application`);
    }

    validateForm(formSection: number): Observable<IApplicationValidationDTO> {
        return this.http.post<IApplicationValidationDTO>(`/donor-portal/application/validate-form/${formSection}`, {});
    }

    checkFormComplete(): Observable<IApplicationValidationDTO> {
        return this.http.post<IApplicationValidationDTO>(`/donor-portal/application/is-form-complete`, {});
    }

    //
    // Submit the application, creating a new Donor instance and return that new donor
    //
    submitApplicationPart1(application: IApplication, ssn: string): Observable<IDonor> {
        let formData: FormData = new FormData();
        formData.append('application', JSON.stringify(application));
        formData.append('socialSecurityNumber', ssn);
        return this.http.post<IDonor>(`/api/v1/donor-portal/application/finalize`, formData);
    }

    //
    // Submit the photos and identity documents that were uploaded by the user (one at a time time to reduce the likelihood
    // of the connectiont timing out due to multiple large uploads.
    //
    submitApplicationPart2(userId: number, id: number, donorId: number, files: File[], ids: File[]): boolean {

        let ok = true;
        for (let i = 0; i < files.length; i++) {

            let jszip = new JSZip();
            let image = files[i];

            jszip.file(image.name, image);
            jszip.generateInternalStream({ type: 'blob', compression: 'DEFLATE' })
                .accumulate()
                .then((content) => {
                    let imageDoc = this.appendUploadDocValues2(userId, id, donorId, content, image.name);
                    this.http.post<boolean>(`/api/v1/donor-portal/application/upload-photo`, imageDoc).subscribe((result) => ok = result);

                    return ok;
                });
        }

        if (ok) {
            for (let i = 0; i < ids.length; i++) {
                let document = ids[i];
                let jszip = new JSZip();

                jszip.file(document.name, document);
                jszip.generateInternalStream({ type: 'blob', compression: 'DEFLATE' })
                    .accumulate()
                    .then((content) => {

                        let identityDoc = this.appendUploadDocValues2(userId, id, donorId, content, document.name);

                        this.http.post<boolean>(`/api/v1/donor-portal/application/upload-identity-doc`, identityDoc).subscribe((result) => ok = result);

                        return ok;
                    });
            }
        }

        if (!ok) {
            throwError('Could not upload photos and/or documents to the server.');
        }

        return ok;
    }

    getAppointmentsEnabled(): Observable<boolean> {
        return this.http.get<boolean>(`/api/v1/donor-portal/application/get-appointments-enabled`);
    }

    //
    // Create a form data instance with values for the user Id, internal and public donor Id #s and the given file
    //
    appendUploadDocValues(userId: number, id: number, donorId: number, file: File): FormData {
        let docFormData: FormData = new FormData();

        docFormData.append('userId', userId.toString());
        docFormData.append('Id', id.toString());
        docFormData.append('donorId', donorId.toString());
        docFormData.append('file', file, file.name);

        return docFormData;
    }

    appendUploadDocValues2(userId: number, id: number, donorId: number, zip: Blob, fileName: string): FormData {
        let docFormData: FormData = new FormData();

        docFormData.append('userId', userId.toString());
        docFormData.append('Id', id.toString());
        docFormData.append('donorId', donorId.toString());
        docFormData.append('file', zip, fileName + '.zip');

        return docFormData;
    }

    getPersonalInformationMetaData(): Observable<IPersonalInformationMetaData> {
        return this.http.get<IPersonalInformationMetaData>('/options/personal-information');
    }

    getAdminUploadedImages(applicationId: number): Observable<IApplicationImageDTO[]> {
        return this.http.get<IApplicationImageDTO[]>(`/api/v1/donor-portal/application/${applicationId}/admin-application-images`);
    }

    getAdminUploadedIdentityImages(applicationId: number): Observable<IApplicationImageDTO[]> {
        return this.http.get<IApplicationImageDTO[]>(`/api/v1/donor-portal/application/${applicationId}/admin-application-identity-images`);
    }

    scrollToFirstInvalidControl(elementRef: ElementRef): void {
        const firstInvalidControl: HTMLElement = elementRef.nativeElement.querySelector('form .ng-invalid');
        if (firstInvalidControl !== null) {
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: this.getTopOffset(firstInvalidControl),
            });
        }
    }

    scrollToControlId(elementRef: ElementRef, controlId: string): void {
        const control: HTMLElement = elementRef.nativeElement.querySelector('#' + controlId);
        if (control != null) {
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: this.getTopOffset(control),
            });
        }
    }

    getTopOffset(controlEl: HTMLElement): number {
        const labelOffset = 50;
        return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
    }
}
