<div class="auth-layout__footer">
    <a href="https://www.theworldeggandspermbank.com/privacy-policy/"
        rel="noopener noreferrer" target="_blank">
        Privacy Policy
    </a>
    &nbsp;|&nbsp;
    <a href="https://www.theworldeggandspermbank.com/accessibility-statement/"
        rel="noopener noreferrer" target="_blank">
        Accessibility Statement
    </a>
    &nbsp;|&nbsp;
    <a href="https://www.theworldeggandspermbank.com/contact-us/" target="_blank">Contact Us</a>
</div>